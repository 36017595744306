<template>
  <ion-grid class="full-height prime-patient-view">
    <ion-row class="full-height">
      <ion-col>
        <ion-row class="margin-top-twenty">
          <ion-col>
            <h4 class="view-title">Stay In Touch - Outreaches</h4>
          </ion-col>
          <ion-col v-if="$can(I.ACCESS_CLIENT_PARTNER)">
            <ion-row class="pad-twenty display-flex valign">
              <ion-col class="text-right pad-lr-ten text-bold">
                View By Practice:
              </ion-col>
              <ion-col size="auto">
                <select class="form-control" @change="onPracticeChange($event)">
                  <option value="">All Practices</option>
                  <option v-for="org in orgs" :key="org.id" :value="org.id">{{ org.name }}</option>
                </select>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <!-- {{ rows }} -->
        <div v-if="isLoading" class="text-center pad-ten full-height">
          <div class="centerItems text-muted display-flex valign full-height full-width">
            <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
          </div>
        </div>
        <div v-else-if="!isLoading && rows.length == 0 && Object.keys(serverParams.columnFilters).length == 0" class="prime-tasks-empty-container">
          <IconCheckCircle class="prime-tasks-empty-icon icon-check-circle-watermark icon-grey" />
          <h5 class="text-grey">No tasks at the moment</h5>
        </div>
        <div v-else>
          <ion-card color="white" class="margin-death" style="margin-bottom:50px !important;">
            <div v-if="isLoadingTableData" class="text-center pad-ten loading-overlay center-center fade-in">
              <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
            </div>
            <div>
              <vue-good-table
                ref="vgt-table"
                mode="remote"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPageDropdown: [10, 20, 30, 40, 50],
                  perPage: perPageHack,
                  dropdownAllowAll: false
                }"
                :totalRows="totalRecords"
                :columns="columns"
                :rows="rows"
                styleClass="prime-table vgt-table"
                :sort-options="{
                  enabled: true
                }"
                @on-row-click="onRowClick"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.label == 'Outreach Staus'">
                    <span v-if="props.row.outreachStatus" class="text-center button-like-status">
                      {{ props.row.outreachStatus }}
                    </span>
                  </span>
                  <span v-else-if="props.column.label == 'Time Since Outreach'">
                    <span v-if="props.row.createdAt" class="text-center text-bold pill" :class="{ 'yellow-pill': showYellowTimeElapsedPill(props.row) }">
                      <span v-if="props.row.closed">---</span>
                      <span v-else-if="renderRequiresResponse(props.row) == 'NO'">---</span>
                      <TimeSince v-else :startdate="props.row.createdAt"></TimeSince>
                      {{ props.row.opened | moment("M/D/YYYY - h:mmA") }}
                    </span>
                  </span>
                  <span v-else-if="props.column.label == 'Outreach At'" class="prime-table-small-date">
                    {{ props.row.createdAt | moment("M/D/YYYY - h:mmA") }}
                  </span>
                  <span v-else-if="props.column.label == 'Task Closed'" class="prime-table-small-date">
                    {{ props.row.closed | moment("M/D/YYYY - h:mmA") }}
                  </span>
                  <ion-row v-else-if="props.column.label == 'Channel'" class="ion-align-items-center">
                    <ion-col size="auto" class="margin-top-ten margin-right-five">
                      <IconChatBubble v-if="props.row.channel == 'sms'" class="icon-small" />
                      <IconPhoneVoice v-if="props.row.channel == 'ivr'" class="icon-small" />
                      <IconPhone v-if="props.row.channel == 'voice'" class="icon-small" />
                      <IconMail v-if="props.row.channel == 'email'" class="icon-small" />
                    </ion-col>
                    <ion-col size="auto">
                      <span
                        class="text-uppercase"
                        :class="{
                          'text-capitalize': props.row.channel === 'ivr' || props.row.channel === 'sms'
                        }"
                        >{{ props.row.channel }}</span
                      >
                    </ion-col>
                  </ion-row>
                  <span v-else-if="props.column.label == 'Pathway'">
                    <span v-if="props.row.pathway" class="text-center text-bold pill"></span>
                    {{ props.row.pathway }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </ion-card>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script>
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconPhoneVoice from "@/components/Global/Icons/IconPhoneVoice";
import IconPhone from "@/components/Global/Icons/IconPhone";
import IconMail from "@/components/Global/Icons/IconMail";
import { addIcons } from "ionicons";
import { more } from "ionicons/icons";
import TimeSince from "@/components/Global/TimeSince";
import { send as httpSend } from "@/services/Api";
import _ from "lodash";
import ModalViewOutreach from "@/components/Chat/ModalViewOutreach";

addIcons({
  "md-more": more.md
});

export default {
  name: "OutreachesPatientOverview",
  components: {
    IconCheckCircle,
    TimeSince,
    IconChatBubble,
    IconPhoneVoice,
    IconPhone,
    IconMail
  },
  props: {
    patientFirstName: String,
    patientLastName: String,
    currentProgramId: Number
  },
  data() {
    return {
      columns: [
        {
          label: "Pathway",
          field: "pathway",
          type: "text",
          width: "110px",
          tdClass: "text-right",
          sortable: true
        },
        {
          label: "Channel",
          field: "channel",
          type: "text",
          tdClass: "bold",
          width: "150px",
          sortable: true
        },
        {
          label: "Outreach Staus",
          field: "outreachStatus",
          type: "text",
          tdClass: "bold",
          width: "150px",
          sortable: true
        },
        {
          label: "Time Since Outreach",
          field: "createdAt",
          width: "160px",
          sortable: true
        },
        {
          label: "Outreach At",
          field: "createdAt",
          type: "text",
          width: "135px",
          sortable: true
        }
      ],
      rows: [
        // row data will get injected here
      ],
      orgs: undefined,
      totalRecords: 0,
      perPageHack: undefined,
      serverParams: {
        id: "0",
        page: "1",
        perPage: "10",
        columnFilters: {
          patientName: this.patientFirstName + " " + this.patientLastName
        },
        sort: []
      },
      selectedtask: undefined,
      activeListItem: undefined,
      isLoading: false,
      isLoadingTableData: true
    };
  },
  async mounted() {
    this.isLoading = true;
    this.getTasks();
    this.getMyOrgs();
  },
  async activated() {
    this.getTasks();
    // put this on the current default route
    // to dismiss the logged out toast
    // or else it hangs around after login
    this.$ionic.toastController.dismiss();
  },
  methods: {
    formatCompleted(value) {
      if (value != null) {
        return true;
      } else {
        return false;
      }
    },
    renderPcpName(rowObj) {
      return (rowObj.provider.title ? rowObj.provider.title + " " : "") + (rowObj.provider.firstName ? rowObj.provider.firstName + " " : "") + (rowObj.provider.lastName ? rowObj.provider.lastName : "");
    },
    renderRequiresResponse(rowObj) {
      let requiresResponse;
      if (rowObj.closed) {
        requiresResponse = "COMPLETED";
      } else if (rowObj.inProgress == false && rowObj.assigned == false) {
        requiresResponse = "NO";
      } else {
        requiresResponse = "YES";
      }

      return requiresResponse;
    },
    renderInProgress(rowObj) {
      let inProgress;
      if (rowObj.inProgress == true) {
        inProgress = "YES";
      } else if (rowObj.positiveResponseCount == 0 || rowObj.closed) {
        inProgress = "---";
      } else {
        inProgress = "NO";
      }

      return inProgress;
    },
    renderCompleted(rowObj) {
      let completed;
      if (rowObj.closed) {
        completed = "YES";
      } else if (rowObj.inProgress) {
        completed = "NO";
      } else if (rowObj.assigned == false) {
        completed = "---";
      } else {
        completed = "NO";
      }

      return completed;
    },
    renderName(rowObj) {
      return (rowObj.patient.lastName ? rowObj.patient.lastName + ", " : "") + (rowObj.patient.firstName ? rowObj.patient.firstName : "");
    },
    getElapsedTimeEnd(closed) {
      const endTimes = closed ? closed : "now";
      return endTimes;
    },
    showYellowTimeElapsedPill(row) {
      const isInactive = !row.inProgress && !row.assigned;
      return row.closed == null && !isInactive;
    },
    showYellowAssignedPill(row) {
      return row.assigned == true || row.inProgress;
    },
    async onRowClick(params) {
      const method = "get";
      const path = document.config.currentPatient + params.row.beneficiaryId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.$ionic.modalController
            .create({
              component: ModalViewOutreach,
              mode: "ios",
              componentProps: {
                propsData: {
                  title: params.row.programName + " Outreach",
                  interactionId: params.row.interactionId || null,
                  channel: params.row.channel || "sms",
                  currentInteraction: params.row,
                  currentPatient: result.data,
                  programName: params.row.programName
                }
              }
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not get current patient. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    filterByStatus(evt) {
      this.$refs["vgt-table"].reset();
      this.updateParams({ page: 1 });
      this.serverParams.columnFilters["filterStatusText"] = evt.target.value;
      this.getTasks();
    },
    debounceSearch: _.debounce(function(evt, paramName) {
      this.serverParams.columnFilters[paramName] = evt.target.value;
      this.getTasks();
    }, 1000),
    handleSearch(evt, paramName) {
      this.serverParams.columnFilters[paramName] = evt.target.value;
      this.getTasks();
    },
    async getTasks() {
      this.isLoadingTableData = true;
      const method = "post";
      const path = document.config.getTasksByProgramId + this.currentProgramId;
      const payload = this.serverParams;

      httpSend({ path, method, payload, authToken: true })
        .then(response => {
          this.rows = response.data.results;
          this.totalRecords = response.data.totalRecords;
          this.isLoading = false;
          this.isLoadingTableData = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.isLoadingTableData = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Patient Roster",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async getMyOrgs() {
      const method = "get";
      const path = document.config.myOrganizations;
      httpSend({ path, method, authToken: true })
        .then(response => {
          this.orgs = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to get your Organizations list",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getTasks();
    },
    onPerPageChange(params) {
      // yucky. There is a bug in vue-good-table and
      // so I had to add this work around as a data variable

      this.perPageHack = params.currentPerPage;
      this.updateParams({ perPage: params.currentPerPage });
      this.getTasks();
    },
    onSortChange(params) {
      // if field name transformation is required

      if (params[0].field == "assigned") {
        this.serverParams.sort = [{ field: "requiresResponse", type: params[0].type }];
      } else {
        this.serverParams.sort = [{ field: params[0].field, type: params[0].type }];
      }

      this.getTasks();
    },
    onPracticeChange(evt) {
      this.isLoading = true;
      this.serverParams.columnFilters.orgIds = evt.target.value;
      this.getTasks();
    }
  }
};
</script>

<style scoped>
/* good table row-specific stuff */

small {
  font-size: 12px;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

.prime-active-conversations-container {
  width: 320px;
}

.prime-conversation-list-container::before {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  top: 0;
  left: 20px;
  right: 20px;
}

.prime-conversation-list-item {
  padding: 20px 20px 20px 40px;
  position: relative;
  font-weight: 400;
  cursor: pointer;
}

.prime-conversation-list-item:after {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  bottom: 0;
  left: 20px;
  right: 20px;
}

.prime-conversation-list-item.active-list-item:after {
  display: none;
}

.prime-conversation-list-item .prime-system-conversation-indicator {
  content: "";
  position: absolute;
  background: var(--color-secondary-button-shade);
  top: 15px;
  bottom: 15px;
  left: 20px;
  width: 5px;
  overflow: hidden;
}

.prime-conversation-list-item .prime-system-conversation-indicator:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 1;
  animation: slide 3s ease-in-out infinite alternate;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 89%, rgba(125, 185, 232, 0) 100%);
}

.prime-conversation-list-item.active-list-item {
  background-color: var(--color-secondary-button);
  border-right: 2px solid var(--ion-color-primary);
}

.list-title {
  font-size: 14px;
  font-weight: 500;
}
.prime-tasks-empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}
.icon-check-circle-watermark {
  width: 140px;
  fill: #dadada;
}

@keyframes slide {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.button-like-status {
  border: 1px solid rgb(241, 242, 247);
  padding: 6px;
  border-radius: 7px;
  background: rgb(241, 242, 247);
  width: 200px;
  font-weight: 500;
  display: block;
  text-align: center;
}
</style>
