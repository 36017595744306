import { render, staticRenderFns } from "./ModalAlert.vue?vue&type=template&id=50109d4a&scoped=true"
import script from "./ModalAlert.vue?vue&type=script&lang=js"
export * from "./ModalAlert.vue?vue&type=script&lang=js"
import style0 from "./ModalAlert.vue?vue&type=style&index=0&id=50109d4a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50109d4a",
  null
  
)

export default component.exports