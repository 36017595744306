var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ion-grid',{staticClass:"full-height prime-patient-view"},[_c('ion-row',{staticClass:"full-height"},[_c('ion-col',[_c('ion-row',{staticClass:"margin-top-twenty"},[_c('ion-col',[_c('h4',{staticClass:"view-title"},[_vm._v("Stay In Touch - Outreaches")])]),(_vm.$can(_vm.I.ACCESS_CLIENT_PARTNER))?_c('ion-col',[_c('ion-row',{staticClass:"pad-twenty display-flex valign"},[_c('ion-col',{staticClass:"text-right pad-lr-ten text-bold"},[_vm._v(" View By Practice: ")]),_c('ion-col',{attrs:{"size":"auto"}},[_c('select',{staticClass:"form-control",on:{"change":function($event){return _vm.onPracticeChange($event)}}},[_c('option',{attrs:{"value":""}},[_vm._v("All Practices")]),_vm._l((_vm.orgs),function(org){return _c('option',{key:org.id,domProps:{"value":org.id}},[_vm._v(_vm._s(org.name))])})],2)])],1)],1):_vm._e()],1),(_vm.isLoading)?_c('div',{staticClass:"text-center pad-ten full-height"},[_c('div',{staticClass:"centerItems text-muted display-flex valign full-height full-width"},[_c('ion-spinner',{staticClass:"spinner-large valign",attrs:{"name":"lines"}})],1)]):(!_vm.isLoading && _vm.rows.length == 0 && Object.keys(_vm.serverParams.columnFilters).length == 0)?_c('div',{staticClass:"prime-tasks-empty-container"},[_c('IconCheckCircle',{staticClass:"prime-tasks-empty-icon icon-check-circle-watermark icon-grey"}),_c('h5',{staticClass:"text-grey"},[_vm._v("No tasks at the moment")])],1):_c('div',[_c('ion-card',{staticClass:"margin-death",staticStyle:{"margin-bottom":"50px !important"},attrs:{"color":"white"}},[(_vm.isLoadingTableData)?_c('div',{staticClass:"text-center pad-ten loading-overlay center-center fade-in"},[_c('ion-spinner',{staticClass:"spinner-large",attrs:{"name":"lines","color":"dark"}})],1):_vm._e(),_c('div',[_c('vue-good-table',{ref:"vgt-table",attrs:{"mode":"remote","pagination-options":{
                enabled: true,
                mode: 'records',
                perPageDropdown: [10, 20, 30, 40, 50],
                perPage: _vm.perPageHack,
                dropdownAllowAll: false
              },"totalRows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"styleClass":"prime-table vgt-table","sort-options":{
                enabled: true
              }},on:{"on-row-click":_vm.onRowClick,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'Outreach Staus')?_c('span',[(props.row.outreachStatus)?_c('span',{staticClass:"text-center button-like-status"},[_vm._v(" "+_vm._s(props.row.outreachStatus)+" ")]):_vm._e()]):(props.column.label == 'Time Since Outreach')?_c('span',[(props.row.createdAt)?_c('span',{staticClass:"text-center text-bold pill",class:{ 'yellow-pill': _vm.showYellowTimeElapsedPill(props.row) }},[(props.row.closed)?_c('span',[_vm._v("---")]):(_vm.renderRequiresResponse(props.row) == 'NO')?_c('span',[_vm._v("---")]):_c('TimeSince',{attrs:{"startdate":props.row.createdAt}}),_vm._v(" "+_vm._s(_vm._f("moment")(props.row.opened,"M/D/YYYY - h:mmA"))+" ")],1):_vm._e()]):(props.column.label == 'Outreach At')?_c('span',{staticClass:"prime-table-small-date"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.createdAt,"M/D/YYYY - h:mmA"))+" ")]):(props.column.label == 'Task Closed')?_c('span',{staticClass:"prime-table-small-date"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.closed,"M/D/YYYY - h:mmA"))+" ")]):(props.column.label == 'Channel')?_c('ion-row',{staticClass:"ion-align-items-center"},[_c('ion-col',{staticClass:"margin-top-ten margin-right-five",attrs:{"size":"auto"}},[(props.row.channel == 'sms')?_c('IconChatBubble',{staticClass:"icon-small"}):_vm._e(),(props.row.channel == 'ivr')?_c('IconPhoneVoice',{staticClass:"icon-small"}):_vm._e(),(props.row.channel == 'voice')?_c('IconPhone',{staticClass:"icon-small"}):_vm._e(),(props.row.channel == 'email')?_c('IconMail',{staticClass:"icon-small"}):_vm._e()],1),_c('ion-col',{attrs:{"size":"auto"}},[_c('span',{staticClass:"text-uppercase",class:{
                        'text-capitalize': props.row.channel === 'ivr' || props.row.channel === 'sms'
                      }},[_vm._v(_vm._s(props.row.channel))])])],1):(props.column.label == 'Pathway')?_c('span',[(props.row.pathway)?_c('span',{staticClass:"text-center text-bold pill"}):_vm._e(),_vm._v(" "+_vm._s(props.row.pathway)+" ")]):_vm._e()]}}])})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }