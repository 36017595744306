import { render, staticRenderFns } from "./PopoverProgramsOptions.vue?vue&type=template&id=ea94d840&scoped=true"
import script from "./PopoverProgramsOptions.vue?vue&type=script&lang=js"
export * from "./PopoverProgramsOptions.vue?vue&type=script&lang=js"
import style0 from "./PopoverProgramsOptions.vue?vue&type=style&index=0&id=ea94d840&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea94d840",
  null
  
)

export default component.exports