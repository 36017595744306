<template>
  <div>
    <div v-if="isLoading" class="center-center fade-in full-height valign margin-top-eighty">
      <ion-spinner
        class="center-center spinner-large valign"
        name="lines"
        color="dark"
      ></ion-spinner>
    </div>
    <div class="pad-top-twenty" v-else>
      <div v-if="showOutreach" class="margin-top-thirty">
        <div @click="showOutreach = undefined" class="link-grey text-bold cursor-pointer valign">
          <ion-icon name="ios-arrow-back" class="icon-back"></ion-icon>
          Back to Programs
        </div>
        <Outreaches
          :patientFirstName="currentPatient.payload.account.firstName"
          :patientLastName="currentPatient.payload.account.lastName"
          :currentProgramId="currentProgramId"
        />
      </div>
      <div v-else>
        <div>
          <ion-row>
            <ion-col class="margin-bottom-ten">
              <h3>Active Programs</h3>
            </ion-col>
          </ion-row>
          <ion-row v-if="activePrograms.length == 0">
            <ion-col class="display-flex valign center-center no-programs">
              <div class="text-muted">
                No programs currently active.
              </div>
            </ion-col>
          </ion-row>
          <ion-row v-for="program in activePrograms" :key="program.id">
            <ion-col class="margin-bottom-twenty">
              <ion-card color="white" class="program-row margin-death">
                <ion-card-content>
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col class="name text-bold">
                        <h5>{{ program.program.name }}</h5>
                      </ion-col>
                      <ion-col></ion-col>
                      <ion-col size="auto">
                        <div class="text-muted text-small">Started</div>
                        <div class="text-bold">
                          {{ program.accountProgram.activated | moment("M/DD/YYYY") }}
                        </div>
                      </ion-col>
                      <ion-col size="auto">
                        <button
                          @click="showActions($event, program)"
                          class="prime-button button-clear margin-lr-ten"
                        >
                          <ion-icon
                            class="prime-button button-clear"
                            color="primary"
                            name="md-more"
                          />
                        </button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
        <ion-row v-if="$can(I.MODIFY_PATIENT)">
          <ion-col
            v-if="currentPatient.payload.enrollmentStatus == 'approved'"
            class="name text-bold"
          >
            <button
              @click="
                addToProgram(
                  'Add Patient to Program',
                  currentPatient.payload.account.id,
                  currentPatient.payload.beneficiary.id
                )
              "
              class="prime-button program-row button-block button-secondary"
            >
              &plus; Add Program
            </button>
          </ion-col>
          <ion-col v-else class="center-center no-programs">
            <div class="text-muted">
              Enroll patient before adding programs.
            </div>
          </ion-col>
        </ion-row>
        <ion-row v-else>
          <ion-col
            v-if="currentPatient.payload.enrollmentStatus == 'approved'"
            class="name text-bold"
          >
            <button
              class="prime-button disabled program-row button-block button-secondary"
              v-tooltip.top-start="{
                content: $orgMissingPermissions('The Program Modification feature')
              }"
            >
              &plus; Add Program
            </button>
          </ion-col>
          <ion-col v-else class="center-center no-programs">
            <div class="text-muted">
              Enroll patient before adding programs.
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="margin-top-twenty">
            <h5>Previous Programs</h5>
          </ion-col>
        </ion-row>
        <div v-if="previousPrograms.length > 0">
          <ion-row v-for="program in previousPrograms" :key="program.id">
            <ion-col>
              <ion-card :color="program.program.active ? 'white' : 'light'" class="program-row">
                <ion-card-content>
                  <ion-grid>
                    <ion-row class="ion-align-items-center">
                      <ion-col class="name text-bold">
                        <h5>{{ program.program.name }}</h5>
                      </ion-col>
                      <ion-col></ion-col>
                      <ion-col size="auto">
                        <div class="text-muted text-small">Started</div>
                        <div class="text-bold">
                          {{ program.accountProgram.activated | moment("M/DD/YYYY") }}
                        </div>
                      </ion-col>
                      <ion-col size="auto" class="margin-left-twenty">
                        <div class="text-muted text-small">Ended</div>
                        <div class="text-bold">
                          {{ program.accountProgram.deactivated | moment("M/DD/YYYY") }}
                        </div>
                      </ion-col>
                      <ion-col size="auto">
                        <button
                          @click="showActions($event, program)"
                          class="prime-button button-clear margin-lr-ten"
                        >
                          <ion-icon
                            class="prime-button button-clear"
                            color="primary"
                            name="md-more"
                          />
                        </button>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
        <div v-else>
          <ion-row>
            <ion-col class="center-center no-programs">
              <div class="text-muted">
                No previous programs to display.
              </div>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { send as httpSend } from "@/services/Api";
import { more } from "ionicons/icons";
import PopoverPatientProgramsOptions from "@/components/Settings/Patient/PopoverProgramsOptions";
import AddPatientToProgram from "@/components/Global/ModalAddPatientToProgram";
import { EventBus } from "@/services/Events.js";
import Outreaches from "@/components/Settings/Patient/Outreaches";

addIcons({
  "md-more": more.md
});

export default {
  name: "Programs",
  props: {
    currentPatient: Object
  },
  data() {
    return {
      allPrograms: [],
      activePrograms: [],
      previousPrograms: [],
      availablePrograms: [],
      isLoading: false,
      showOutreach: false,
      currentProgramId: undefined
    };
  },
  async mounted() {
    this.getPrograms();

    EventBus.$on("reloadPrograms", () => {
      this.getPrograms();
    });
    // TODO: remove this listener after we get multi-program properly set up
    EventBus.$on("showOutreaches", id => {
      this.currentProgramId = id;
      this.showOutreach = true;
    });
  },
  components: {
    Outreaches
  },
  methods: {
    async getPrograms() {
      this.isLoading = true;
      const method = "get";
      const path =
        document.config.patientApi + this.currentPatient.payload.beneficiary.id + "/programs";

      httpSend({ path, method, authToken: true })
        .then(response => {
          // first remove Text Triage by id
          let rtnArr = response.data;
          if (rtnArr.length > 0) {
            const allBenePrograms = rtnArr.filter(e => e.program.id != 1);

            // this.activePrograms = allBenePrograms;

            this.activePrograms = allBenePrograms.filter(e => e.accountProgram.active == true);
            this.previousPrograms = allBenePrograms.filter(
              e => e.accountProgram.active != true && e.accountProgram.deactivated
            );
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load programs",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async addToProgram(title, accountId, beneId) {
      this.$ionic.modalController
        .create({
          component: AddPatientToProgram,
          mode: "ios",
          cssClass: "prime-modal-wide",
          componentProps: {
            data: {
              accountId: accountId,
              beneId: beneId
            },
            propsData: {
              title: title
            }
          }
        })
        .then(m => m.present());
    },
    goToNamedRoute(routeName) {
      this.$router.push({
        name: routeName
      });
    },
    showActions(ev, program) {
      this.$ionic.popoverController
        .create({
          component: PopoverPatientProgramsOptions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: {
              program: program,
              currentPatient: this.currentPatient.payload
            }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>
<style scoped>
button.prime-button.button-clear {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
}
.no-programs {
  height: 100px;
}
.program-row {
  height: 85px;
}
</style>
