<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <p class="message" v-dompurify-html="message"></p>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-block">
              <span>OK</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
export default {
  name: "ModalAlert",
  props: {
    title: String,
    message: String
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.message {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
ion-footer {
  padding: 20px;
}
</style>
