<template>
  <ion-list mode="ios">
    <!-- <ion-item v-if="program.accountProgram.programId == 2" @click="showOutreaches()" class="cursor-pointer"> -->
    <ion-item @click="showOutreaches(program.accountProgram.programId)" class="cursor-pointer">
      <ion-label color="dark" mode="ios">View Outreaches</ion-label>
    </ion-item>
    <ion-item @click="showProgramDescription(program)" class="cursor-pointer">
      <ion-label color="dark" mode="ios">View Program Description</ion-label>
    </ion-item>
    <ion-item v-if="$can(I.MODIFY_PATIENT)" lines="none" @click="confirmChangeUserStatus(program)" class="cursor-pointer">
      <ion-label :color="program.accountProgram.active ? 'danger' : 'dark'" mode="ios">{{ program.accountProgram.active ? "Remove From Program" : "Add To Program" }}</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import { send as httpSend } from "@/services/Api";
import ModalAlert from "@/components/Global/ModalAlert";

export default {
  name: "PopoverProgramsOptions",
  methods: {
    showOutreaches(id) {
      this.$ionic.popoverController.dismiss();
      EventBus.$emit("showOutreaches", id);
    },
    confirmChangeUserStatus(program) {
      this.$ionic.popoverController.dismiss();

      if (program.program.active == true) {
        this.$ionic.alertController
          .create({
            header: program.accountProgram.active ? "Confirm Removal" : "Confirm Addition",
            message: program.accountProgram.active
              ? "Are you sure you want to remove <strong>" + this.currentPatient.account.firstName + " " + this.currentPatient.account.lastName + "</strong> from the <strong>" + program.program.name + "</strong> program? Once removed, the user can be re-added."
              : "Are you sure you want to add <strong>" + this.currentPatient.account.firstName + " " + this.currentPatient.account.lastName + "</strong> to the <strong>" + program.program.name + "</strong> program?",
            mode: "ios",
            buttons: [
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {}
              },
              {
                text: program.accountProgram.active ? "Remove From Program" : "Add To Program",
                handler: () => {
                  this.changeUserStatus(program);
                }
              }
            ]
          })
          .then(a => a.present());
      } else {
        this.$ionic.alertController
          .create({
            header: "Program Not Available",
            message: "This program is not currently enabled for your organization.",
            mode: "ios",
            buttons: [
              {
                text: "Ok",

                cssClass: "secondary",
                handler: () => {}
              }
            ]
          })
          .then(a => a.present());
      }
    },
    changeUserStatus(program) {
      const method = program.accountProgram.active ? "delete" : "post";
      const path = program.accountProgram.active ? document.config.SITUserAccount + this.currentPatient.beneficiary.accountId + "/program/" + program.accountProgram.programId : document.config.SITUserAccount + "activate/" + this.currentPatient.beneficiary.accountId + "/program/" + program.accountProgram.programId;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: program.accountProgram.active ? "User was successfully removed" : "User was successfully added",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.$ionic.popoverController.dismiss();
          EventBus.$emit("reloadPrograms");
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to change user status",
              message: "Please try again later",
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    showProgramDescription(program) {
      this.$ionic.popoverController.dismiss();

      this.$ionic.modalController
        .create({
          component: ModalAlert,
          mode: "ios",
          componentProps: {
            propsData: {
              title: "Program - " + program.program.name,
              message: program.program.longDescription
            }
          }
        })
        .then(m => m.present());
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
