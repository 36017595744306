<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">{{ selectedProgram ? title + " - " + selectedProgram.program.name : title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <div v-if="isLoading" class="center-center fade-in">
        <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
      </div>
      <div v-else>
        <ion-col v-if="availablePrograms.length == 0" class="display-flex valign center-center">
          <div class="center-center pad-top-thirty text-muted">No programs available to add.</div>
        </ion-col>
        <div v-else>
          <div v-if="!selectedProgram">
            <ion-row v-for="program in availablePrograms" :key="program.id">
              <ion-col>
                <ion-card class="cursor-pointer" color="white" @click="selectProgram(program)">
                  <ion-card-content class="margin-death padding-death">
                    <ion-grid>
                      <ion-row>
                        <ion-col size="auto">
                          <IconPrograms class="prime-main-menu-icon" />
                        </ion-col>
                        <ion-col id="dashboardRowShortcut" size="10">
                          <ion-row>
                            <ion-col>
                              <span class="name text-bold">{{ program.program.name }}</span>
                            </ion-col>
                          </ion-row>
                          <div class="text-small text-muted pad-top-ten" v-dompurify-html="program.program.shortDescription"></div>
                        </ion-col>
                        <ion-col></ion-col>
                        <ion-col size="auto" class="ion-align-self-center">
                          <IconCarrot id="dashboardShortcut" class="icon-medium icon-blue valign" />
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </div>
          <div v-else>
            <ion-row>
              <h2 class="text-bold">{{ selectedProgram.program.name }}</h2>
            </ion-row>
            <ion-row>
              <ion-col v-dompurify-html="selectedProgram.program.longDescription"></ion-col>
            </ion-row>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer v-if="selectedProgram">
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="selectedProgram = undefined" class="prime-button button-secondary button-block">
              <span>Back</span>
            </button>
          </ion-col>
          <ion-col>
            <button @click="confirmEnrollPatientInProgram(selectedProgram)" class="prime-button button-block margin-left-ten">
              <span>Add Program</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import IconCarrot from "@/components/Global/Icons/IconCarrot";
import IconPrograms from "@/components/Global/Icons/IconPrograms";
import { EventBus } from "@/services/Events.js";

export default {
  name: "ModalAddPatientToProgram",
  components: {
    IconCarrot,
    IconPrograms
  },
  props: {
    title: String
  },
  data() {
    return {
      programData: [],
      isLoading: false,
      selectedProgram: undefined,
      availablePrograms: []
    };
  },
  mounted() {
    this.getProgramsByPatient();
  },
  methods: {
    selectProgram(program) {
      this.selectedProgram = program;
    },
    confirmEnrollPatientInProgram(program) {
      this.$ionic.alertController
        .create({
          header: "Confirm Add Patient to Program",
          message: "Are you sure you want to add this patient to the <strong>" + program.program.name + "</strong> program?",
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Enroll",
              handler: () => {
                this.enrollPatientInProgram(program);
              }
            }
          ]
        })
        .then(a => a.present());
    },
    enrollPatientInProgram(program) {
      const path = document.config.programsApi + program.accountProgram.programId + "/patients/enroll?patients=" + this.accountId;
      const method = "post";

      httpSend({ path, method, authToken: true })
        .then(
          // fix for race condition
          // if no race condition found in the future remove the setTimeout
          setTimeout(function() {
            EventBus.$emit("reloadPrograms");
          }, 1500),
          this.$ionic.modalController.dismiss()
        )
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to enroll patient in program",
              message: "Please try again later",
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async getProgramsByPatient() {
      this.isLoading = true;
      const method = "get";
      const path = document.config.patientApi + this.beneId + "/programs?all=true";

      httpSend({ path, method, authToken: true })
        .then(response => {
          let rtnArr = response.data;
          if (rtnArr.length > 0) {
            // first remove Text Triage by id
            const benePrograms = rtnArr.filter(e => e.program.id != 1);
            // then filter out active programs for the patient; only show inactives
            this.availablePrograms = benePrograms.filter(e => e.accountProgram.active != true);
          }
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load programs",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
ion-footer {
  padding: 20px;
}
</style>
