<template>
  <div class="prime-modal">
    <!-- {{ currentInteraction }} -->
    <ion-header class="prime-view-past-conversation-header">
      <ion-toolbar>
        <IconPhoneVoice v-if="channel == 'ivr'" slot="start" class="icon-small" />
        <IconChatBubble v-else slot="start" class="icon-small" />
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
      <ion-toolbar v-if="channel == 'ivr'" class="top-divider text-small"> <span class="text-bold">IVR Channel Info:</span> This is a report of an IVR (Interactive Voice Response) call. It displays structured/interpreted responses, and is not a word-for-word transcript.</ion-toolbar>
      <ion-toolbar v-else-if="programName" class="top-divider">
        <div class="text-small text-muted">Generated from Program</div>
        <div class="text-bold">{{ programName }}</div>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-view-past-conversation-container">
      <MessagePanelSingleInteraction class="view-conversation-message-panel pad-bottom-twenty" :interactionId="interactionId" :currentPatient="currentPatient" :convoChannel="'all'" :showMessageFilters="false" />
    </ion-content>
    <ion-footer>
      <ion-row class="pad-twenty">
        <ion-col>
          <button @click="goToTasks()" class="prime-button button-secondary button-block">
            Go to Tasks
          </button>
          <div v-if="$can(I.ACCESS_CLIENT_PARTNER)">
            <div v-if="currentInteraction">
              <button v-if="!currentInteraction.clientPartnerReviewed" @click="markAsReviewed()" class="prime-button button-primary button-block margin-top-ten">
                <ion-icon name="md-checkmark"></ion-icon>
                Mark as Reviewed
              </button>
              <div v-else-if="currentInteraction.clientPartnerReviewed" class="text-muted margin-top-ten text-center text-bold center-center text-small">Reviewed on {{ currentInteraction.clientPartnerReviewedAt | moment("M/D/YYYY - h:mmA") }}</div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-footer>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { checkmark } from "ionicons/icons";
import MessagePanelSingleInteraction from "./MessagePanelSingleInteraction";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconPhoneVoice from "@/components/Global/Icons/IconPhoneVoice";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import router from "@/router";

addIcons({
  "md-checkmark": checkmark.md
});

export default {
  name: "ModalViewOutreach",
  props: {
    title: { type: String, default: "View Conversation" },
    interactionId: Number,
    currentPatient: Object,
    programName: String,
    channel: String
  },
  components: {
    MessagePanelSingleInteraction,
    IconChatBubble,
    IconPhoneVoice
  },
  data() {
    return {
      currentTask: undefined,
      currentInteraction: undefined
    };
  },
  async mounted() {
    this.getInteraction();
  },
  methods: {
    markAsReviewed() {
      this.currentInteraction.clientPartnerReviewed = true;
      this.currentInteraction.clientPartnerReviewedAt = new Date();
      this.updateInteraction();
    },
    updateInteraction() {
      const method = "put";
      const path = document.config.messageApi;
      const payload = this.currentInteraction;

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "This interaction has been marked as reviewed.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => {
              m.present();
              EventBus.$emit("conversationUpdated");
            });
        })
        .catch(error => {
          this.catchError(error, "Could not set Interaction Reviewed Status. Please try again later.");
        })
        .finally(() => (this.loading = false));
    },
    getInteraction() {
      const method = "get";
      const path = document.config.messageApi + this.interactionId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.currentInteraction = result.data;
        })
        .catch(error => {
          this.catchError(error, "Could not get Interaction. Please try again later.");
        })
        .finally(() => (this.loading = false));
    },
    goToTasks() {
      this.$ionic.modalController.dismiss();
      router.push({
        path: "/patient-settings/tasks/" + this.currentInteraction.beneficiary_id
      });
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style>
.view-conversation-message-panel .active-conversation-header {
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}
.view-conversation-message-panel .active-conversation-title-container {
  display: none;
}
.prime-modal ion-content.prime-view-past-conversation-container ion-footer {
  display: none;
}
.prime-modal ion-header.prime-view-past-conversation-header {
  border-bottom: 2px solid var(--ion-color-light-shade);
}
.prime-modal ion-content.prime-view-past-conversation-container {
  --background: var(--ion-modal-secondary-background);
}
.prime-modal ion-content.prime-view-past-conversation-container ion-content,
.prime-modal ion-content.prime-view-past-conversation-container ion-toolbar {
  --background: var(--ion-modal-secondary-background);
}
</style>
